import React from 'react'
import { Link as GatsbyLink, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Box, Button, Heading, Stack } from '@chakra-ui/react'
import LayoutPublic from "../components/layouts/layoutPublic"
import WysiwygContent from "../components/content/content";

const PostTemplate = (props) => {
  const {
    seoDescription
  } = props.data.post
  return (
    <LayoutPublic lang={process.env.GATSBY_LANG}>
      <Helmet>
        <meta name='description' content={seoDescription ? seoDescription : ''} />
      </Helmet>
      <Box
        px={{ base: 0, md: "2rem", lg: "2rem", xxl: "10rem" }}
        py={{ base: 0, md: "1rem", lg: "1rem", xxl: "2rem" }}
      >
        <Stack spacing={{ base: "2rem", lg: "3rem" }} mx='auto' maxW="800px" my="2rem" borderRadius="lg" background='white' p={{ base: '2rem', lg: '3rem' }}>
          <Box>
            <Button as={GatsbyLink} variant="outline" to='../'>
              ← Retour
            </Button>
          </Box>

          <Heading color='blue.900' mb="2rem" as='h1'>
            {props.data.post.title}
          </Heading>
          <Box>
            <WysiwygContent data={JSON.parse(props.data.post.content.raw)} references={props.data.post.content.references} />
          </Box>
        </Stack>
      </Box>
    </LayoutPublic>
  )
}

export default PostTemplate


export const pageQuery = graphql`
  query post(
      #$slug: String!,
      $contentfulID:  String!,
      #$otherLang: String!
    ){
    post: contentfulPost(contentful_id: {eq: $contentfulID}) {
      slug
      title
      seoDescription
      content {
        raw
        references{
          contentful_id
          ... on ContentfulAsset { file{ url } }
        }
      }
    }
  }
`